/* src/components/Login.css */
.login-container {
    width: 300px;
    margin: 100px auto;
    padding: 30px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .login-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .login-container form div {
    margin-bottom: 15px;
  }
  
  .login-container label {
    display: block;
    margin-bottom: 5px;
  }
  
  .login-container input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  
  .login-container button {
    width: 100%;
    padding: 10px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .login-container button:hover {
    background-color: #218838;
  }
  