/* Side Menu Styling */
.side-menu-container {
  position: relative;
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: lightgray;
  color:white;
  overflow-x: hidden;
  transition: 0.3s;
  padding-top: 60px;
}

.sidenav.open {
  width: 200px;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  color: black;
  border: none;
  background: transparent;
  cursor: pointer;
}

.menu-item {
  padding: 10px 15px;
  display: flex;
  align-items: center;
  color: black;
  text-decoration: none;
  font-size: 14px;
  transition: 0.2s;
}

.menu-item .icon {
  margin-right: 10px;
  font-size: 16px;
}

.menu-item:hover {
  color: #f1f1f1;
}
.menuname
{
  float: right;
    margin-right: 50%;
    /* left: 5%; */
   /* margin-top: 1%; */
    font-size: 24px; /* Adjust size as needed */
    font-weight: bold;
    padding: 10px;
    text-align: center; /* Center align text */
    /*background-color: #f0f0f0;  Background color */
}
.openbtn {
  font-size: 30px;
  cursor: pointer;
  background-color: lightgray;
  color: black;
  border: none;
  padding: 10px 15px;
}

.logout-btn {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: black;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: absolute;
    margin-left: 90%;
}

.logout-btn .icon {
  margin-right: 5px;
}
